import Vue from "vue";
// import http from "../http/axiosConfig";
import axios from "axios";

export default {
  async logout() {
    await Vue.prototype.$removeItem("user-params");
  },

  async signIn(model) {
    let validationRes = [];
    let stringAccountLoginValidation = 'Email ou Senha Incorreta';
    let URLAPI = model.urlAPI ? model.urlAPI : "https://api.accd.app/api";
    let params = {
      "messageType": "string",
      "aggregateId": "string",
      "validationResult": {},
      "tenanty": model.tenanty,
      "email": model.email,
      "password": model.password
    };
    return axios.post(URLAPI + '/user/token', params)
      .then(response => {
        Vue.prototype.$setItem("user-params", response.data.data);
        Vue.prototype.$setItem("url-api", URLAPI);
        return response;
      })
      .catch(error => {
        error.response.data.data.forEach(({ key, value }) => {
          let traduzValue = value.includes('incorrect') ? stringAccountLoginValidation : '';
          validationRes = { data: { success: false, key: key + ' erro', errorNoFormat: value, error: traduzValue } };
        });
        return validationRes.data ? validationRes : {
          data: { success: false }
        };
      });
  },

  async getParams() {
    let params = await Vue.prototype.$getItem("user-params");
    return params;
  },

  async getToken() {
    let params = await Vue.prototype.$getItem("user-params");
    let token = null;
    if (params) {
      token = params.accessToken;
    }
    return token;
  },

};
