import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  hashbang: false,
  history: true,
  mode: "hash",
  routes: [
    {
      // catch all 404 - define at the very end
      path: "/404",
      name: 'NotAuthorized',
      component: () => import("../views/Auth/notAuthorized.vue")
    }, {
      path: '*',
      redirect: '404'
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login/LoginPage.vue")
    },
    {
      path: "/HomePage",
      name: "HomePage",
      redirect: '/home',
      component: () => import("../views/HomePage.vue"),
      children: [
        {
          path: "/schemas",
          name: "Schemas",
          component: () => import("../views/Schemas.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/actions",
          name: "Actions",
          component: () => import("../views/Actions.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/views",
          name: "Views",
          component: () => import("../views/Views.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/analysis",
          name: "AnalysisPage",
          component: () => import("../views/Analysis.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/dashboards",
          name: "DashboardPage",
          component: () => import("../views/Dashboards"),
          meta: { requiresAuth: true }
        }
      ],
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = await Vue.prototype.$auth.getParams();

  if (requiresAuth && currentUser.length == 0) {
    next('/404');
    return;
  } else if (requiresAuth && currentUser) {
    next();
    return;
  } else {
    next();
    return;
  }
});
export default router;
