import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import authService from "./services/authService";
import entityService from './services/entityService';
import NProgress from 'vue-nprogress';
import packageJson from '../package.json';

import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Cancelar',
  color: 'error',
  //icon: 'mdi-user',
  title: 'Atenção',
  width: 400,
  property: '$confirm'
})

Vue.use(NProgress);
const nprogress = new NProgress();

import VueLocalForage from 'vue-localforage';
Vue.use(VueLocalForage);

import JsonViewer from 'vue-json-viewer';
Vue.use(JsonViewer);

import JSONView from "vue-json-component";
Vue.use(JSONView);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VJsoneditor from 'v-jsoneditor';
Vue.use(VJsoneditor);

import VueBus from 'vue-bus';
Vue.use(VueBus);


import Notifications from 'vue-notification';
Vue.use(Notifications);

Vue.config.productionTip = false;

Vue.prototype.$version = packageJson.version;
Vue.prototype.$startProgress = function () { nprogress.start(); };
Vue.prototype.$doneProgress = function () { nprogress.done(); };
Vue.prototype.$auth = authService;
Vue.prototype.$entityServece = entityService;

Vue.prototype.$isConfirmeDelete = async function (msg) {
  return await Vue.prototype.$confirm(
    `${msg} ?`
  );
};

new Vue({
  render: h => h(App),
  router,
  vuetify,
  nprogress,
}).$mount('#app');
